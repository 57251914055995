import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Row, Col } from 'react-bootstrap'

const Office = ({ location }) => {

  const data = useStaticQuery(graphql`
    query {
      office: file(relativePath: { eq: "icons/building.svg" }) {
        publicURL
      }
    }
  `)

  const Hr = () => <hr className="dots-hr my-5" />
  const OfficeIcon = () => <img src={data.office.publicURL} alt="事業所" style={{ width: '4rem', display: 'inline-block' }} />

  return (
    <Layout location={location} title="事業所" >
      <SEO title="事業所" description="各事業所の情報とアクセスマップをご覧いただけます。" />

      <div className="page-head">
        <Container expand="lg">
          <h2>事業所</h2>
          <p>各事業所の情報とアクセスマップをご覧いただけます。</p>
        </Container>
        <div className="bottom-border" />
      </div>

      <Container expand="lg">

        <OfficeIcon />
        <h2 className="d-inline-block ml-3">本社</h2>
        <Row>
          <Col lg={6} className="pr-0">

            <p>〒450-0003<br />愛知県名古屋市中村区名駅南⼆丁⽬14番19号 住友⽣命名古屋ビル21階</p>
            <p><strong>
              TEL 052-581-2600（代表） <br />
              FAX 052-533-3611
            </strong></p>
            <p>路線「名古屋駅」桜通⼝側より徒歩約10分</p>
          </Col>
          <Col lg={6}>
            <iframe title="本社" width="100%" height="400" frameBorder="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3261.650179709609!2d136.88639235!3d35.16534415000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600376df7ae7f785%3A0xd86238c869359624!2z44CSNDUwLTAwMDMg5oSb55-l55yM5ZCN5Y-k5bGL5biC5Lit5p2R5Yy65ZCN6aeF5Y2X77yS5LiB55uu77yR77yU4oiS77yR77yZIOS9j-WPi-eUn-WRveWQjeWPpOWxi-ODk-ODqw!5e0!3m2!1sja!2sjp!4v1444211005808"></iframe>
          </Col>
        </Row>
        <Hr />

        <OfficeIcon />
        <h2 className="d-inline-block ml-3">東京事業所</h2>
        <Row>
          <Col lg={6} className="pr-0">
            <p>〒101-0041<br />東京都千代田区神田須田町1-5 ディアマントビル7階</p>
            <p><strong>TEL 03-6206-4828（代表）<br />FAX 03-6206-4829</strong></p>
            <p>東京メトロ銀座線「神田駅」6番出口より徒歩2分<br />JR「秋葉原駅」電気街口改札より徒歩8分</p>
          </Col>
          <Col lg={6}>
            <iframe title="東京事業所" width="100%" height="400" frameBorder="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.221590280977!2d139.77014!3d35.6961643!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c032637fbe3%3A0x7c5032eec0064e8a!2z44CSMTAxLTAwNDEg5p2x5Lqs6YO95Y2D5Luj55Sw5Yy656We55Sw6aCI55Sw55S677yR5LiB55uu77yV4oiS77yR77yUIOODh-OCo-OCouODnuODs-ODiOODk-ODqw!5e0!3m2!1sja!2sjp!4v1675058627833!5m2!1sja!2sjp"></iframe>
          </Col>
        </Row>
        <Hr />

        <OfficeIcon />
        <h2 className="d-inline-block ml-3">⻘森事業所</h2>
        <Row>
          <Col lg={6} className="pr-0">
            <p>〒030-0861<br />⻘森県⻘森市⻑島⼆丁⽬13番1号 AQUA⻘森スクエアビル7階</p>
            <p><strong>TEL 017-764-0711（代表）<br />FAX 017-764-0712</strong></p>
            <p>⻘森市営バス「県庁前」より徒歩約1分</p>
          </Col>
          <Col lg={6}>
            <iframe title="⻘森事業所" width="100%" height="400" frameBorder="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4629.410598701483!2d140.73736308438248!3d40.82377626972028!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f9b9f0336a4eb2d%3A0x939033735bcd3d7f!2z44CSMDMwLTA4NjEg6Z2S5qOu55yM6Z2S5qOu5biC6ZW35bO277yS5LiB55uu77yR77yT4oiS77yR!5e0!3m2!1sja!2sjp!4v1503892707462"></iframe>
          </Col>
        </Row>
        <Hr />
      </Container>
    </Layout>
  )
}

export default Office